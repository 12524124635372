/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

:root {

	// COLOURS
	// ---------------

	--color1:               #000;
	--color2:               #CC0000; // red
	--color3:               #F26534; // orange
	--color4:               #002459; // blue


	// TYPOGRAPHY
	// ---------------

	--text-color:           #333;
	--text-base-size:        16px;
	--base-line-height:      1.612;
	--base-type-spacing:     1.875rem;

	--font-family-primary:   "Archivo", Helvetica, Arial, sans-serif;
	--font-family-secondary: var(--font-family-primary);
	--font-family-headings:  var(--font-family-secondary);

	--hr-color:              #DDD;

}
